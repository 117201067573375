import React from "react"
import Layout from "../components/layout"
import PostList from "../components/PostList"
import { Section } from "../utils"

const Posts = () => (
  <Layout>
    <PostList />
  </Layout>
)

export default Posts
