import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
//import LanguageContext from "../contexts/LanguageContext"
import { styles, content, Paragraph } from "../utils"

const PostList = () => {
  //const { lang } = useContext(LanguageContext)

  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            slug
            title
            teaser {
              teaser
            }
            fr: createdAt(locale: "fr-FR", formatString: "Do MMM YYYY")
          }
        }
      }
    }
  `)

  return (
    <>
      <PostContainer>
        <SideBar>
          <Paragraph>{content.BlogPresentation.fr}</Paragraph>
          <small>© 2019 Mathias Béraud</small>
        </SideBar>
        <ListWrapper>
          {data.allContentfulBlogPost.edges.map(({ node }, i) => {
            return (
              <ItemWrapper key={i}>
                <Link to={`/posts/${node.slug}`}>
                  <ContentWrapper>
                    <div className="post-content">
                      <h2>{node.title}</h2>
                      <p>{node.teaser.teaser}</p>
                    </div>
                    <div className="post-data">
                      <p>{node.fr}</p>
                    </div>
                  </ContentWrapper>
                </Link>
              </ItemWrapper>
            )
          })}
        </ListWrapper>
      </PostContainer>
    </>
  )
}

export default PostList

const PostContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 105rem 1fr;
  grid-column-gap: 45px;
  padding: 0 1.5rem;

  @media screen and (max-width: 900px) {
    grid-template-columns: auto;
    grid-column-gap: 0;
  }
`
const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 0 0 1rem;
  @media screen and (max-width: 900px) {
    display: none;
  }
  & > small {
    margin: 3rem 0;
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 0 2rem 0 0;
  @media screen and (max-width: 900px) {
    padding: 0;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  &:not(:first-child) {
    border-bottom: 1px dotted ${styles.colors.mediumGrey};
  }
  & > a,
  a:link,
  a:visited {
    text-decoration: none;
    color: ${styles.colors.primaryFont};
    width: 100%;
    &:hover {
      background-color: ${styles.colors.primaryTransparent};
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  padding: 1rem 2rem;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }

  & > .post-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-right: 3rem;
    width: 80%;
    @media screen and (max-width: 560px) {
      width: 100%;
    }
  }
  & > .post-data {
    text-align: right;
    align-self: flex-start;
  }
  p {
    margin: 1rem 0;
  }
`
